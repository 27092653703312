import React, { useEffect, useState } from 'react';
import 'primereact/resources/themes/saga-blue/theme.css'; // or any other theme
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';

export default function AuditTrail(props) {
  // const actionBodyTemplate = (rowData) => {
  //   return `Edit ${rowData?.FieldName} from ${rowData?.OldValue} to ${rowData?.NewValue}`;
  // };

  const actionBodyTemplate = (rowData) => {
    const formatValue = (value) => {
      if (value === "Internally Reviewed") {
        return "Internally Enriched Validated";
      } else if (value === "ExternallyReviewed") {
        return "Externally Enriched Validated";
      } else if (value === "") {
        return "Null";
      }
      return value;
    };
  
    if (rowData?.FieldName === "HFM Code") {
      let [oldHFMSubCode, oldHFMCode] = rowData?.OldValue.split(",");
      oldHFMSubCode = oldHFMSubCode ? oldHFMSubCode : null;
      oldHFMCode = oldHFMCode ? oldHFMCode : null;
      return `Edit Field: HFM Code from ${oldHFMCode} to ${rowData?.HFMCode} and HFM Sub Code from ${oldHFMSubCode} to ${rowData?.HFMSubCode}`;
    }
    
    const oldValueFormatted = formatValue(rowData?.OldValue);
    const newValueFormatted = formatValue(rowData?.NewValue);
  
    return `Edit Field: ${rowData?.FieldName} from ${oldValueFormatted} to ${newValueFormatted}`;
  };
  

  const formatDate = (dateString) => {
    if (!dateString) return "";
    return moment.utc(dateString).local().format('YYYY-MM-DD HH:mm:ss'); 
  };

  const dateBodyTemplate = (rowData) => {
    return formatDate(rowData?.TimeStamp);
  };

  let attributes = {
    value: props.auditData,
    dataKey: "RowKey",
    loading: props.isAuditLoading,
    rows: 5,
    paginator: true,
    sortField: "Date",
    sortOrder: -1
  };
 
  return (
    <DataTable {...attributes}>
      <Column 
        body={dateBodyTemplate}
        header="Date/Time"
      >
      </Column>
      <Column
        body={actionBodyTemplate}
        header="Action"
      >
      </Column>
      <Column 
        field="UserName"
        header="User"
      >
      </Column>
    </DataTable>
  );
}
